import React, { useEffect, useState } from 'react';
import NotificationModal from './components/Dashboard/modal/NotificationModal';
import Close from './assets/icons/close.svg';
import { useSelector } from 'react-redux';
import { axiosGET, axiosPATCH } from './utility/services/common';
import { Configuration } from './configuration';
import { filter } from 'lodash';

const Notification = () => {
  const [modal, setModal] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [notification, setNotification] = useState([]);

  useEffect(() => {
    isAuthenticated && loadNotification();
  }, [isAuthenticated]);

  const loadNotification = () => {
    axiosGET(`${Configuration.booking.notification}`).then((res) => {
      setNotification(filter(res, { status: '1' }));
    });
  };

  const onClickClose = async (id) => {
    setModal(false);

    await axiosPATCH(`${Configuration.booking.updateNotification}/${id}/`).then(
      () => {
        loadNotification();
      }
    );
  };

  return (
    isAuthenticated &&
    notification?.length > 0 && (
      <div className='notification'>
        {notification?.map(
          (item) =>
            parseInt(item?.status) === 1 && (
              <div className='single-noti' key={`noti${item.id}`}>
                <div>
                  <p>
                    Your game is 72 hours away. You can cancel up until tomorrow
                    12:00pm.
                  </p>
                  <p className='link' onClick={() => setModal(true)}>
                    Read more
                  </p>
                </div>
                <div onClick={() => onClickClose(item.id)}>
                  <img src={Close} alt='Close' width={30} height={30} />
                </div>
                {modal && (
                  <NotificationModal
                    modal={modal}
                    setModal={setModal}
                    bookingId={item.booking}
                    onClickClose={() => onClickClose(item.id)}
                  />
                )}
              </div>
            )
        )}
      </div>
    )
  );
};

export default Notification;
